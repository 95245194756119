html,
body {
  padding: 0;
  margin: 0;
  font-family: "ColfaxWeb", sans-serif !important;
  font-size: 1rem !important;
  color: var(--chakra-colors-grey-900) !important;
  height: 100%;
  min-height: 100vh !important;
  min-height: -webkit-fill-available !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input[type="radio"] {
  display: none;
}

.caret-salmon {
  caret-color: var(--chakra-colors-h-salmon-pink);
}

/* Knock notification override classes */

.rnf-notification-feed__type {
  text-transform: uppercase;
  margin-right: 22px !important;
  font-weight: 600 !important;
  color: var(--chakra-colors-grey-900) !important;
}

.rnf-notification-feed-popover__inner {
  border-radius: 6px !important;
}

.rnf-notification-feed__header {
  padding: 20px 12px 20px 20px !important;
}

.rnf-dropdown {
  color: var(--chakra-colors-h-mid-grey) !important;
  width: auto !important;
}

.rnf-dropdown select {
  outline: none !important;
}

.rnf-mark-all-as-read {
  font-weight: 600;
  color: var(--chakra-colors-h-salmon-pink) !important;
}

.rnf-mark-all-as-read svg {
  margin-left: 8px !important;
}

.rnf-mark-all-as-read:disabled {
  opacity: 0.5;
}

.rnf-empty-feed__header {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: var(--chakra-colors-grey-900) !important;
}

.rnf-empty-feed__body {
  font-size: 1rem !important;
  color: var(--chakra-colors-grey-900) !important;
}

.rnf-empty-feed__powered-by-knock {
  display: none;
}

.rnf-notification-cell__unread-dot {
  top: 18px !important;
  height: 7px !important;
  width: 7px !important;
  border: none !important;
  left: 7px !important;
  background: var(--chakra-colors-h-salmon-pink) !important;
}

.rnf-notification-cell__content {
  font-size: 0.875rem !important;
  color: var(--chakra-colors-grey-900) !important;
  line-height: 20px !important;
}

.rnf-notification-cell__timestamp {
  font-size: 0.875rem !important;
  color: var(--chakra-colors-h-mid-grey) !important;
  line-height: 20px !important;
}

.rnf-notification-cell__inner {
  padding-left: 0 !important;
  padding-right: 0 !important;
  border-bottom: 1px solid var(--chakra-colors-h-keyline-grey) !important;
}

.rnf-notification-cell:hover {
  background: var(--chakra-colors-h-keyline-grey) !important;
}

.rnf-notification-cell__inner:hover {
  background: none !important;
}

.rnf-notification-cell {
  padding-left: 20px;
  border: none !important;
}

.rnf-notification-cell__content-outer {
  margin-left: 0 !important;
  padding-right: 8px !important;
}

.rnf-archive-notification-btn {
  padding: 2px 12px 0 0 !important;
  height: 20px !important;
  width: auto !important;
}

/* Frigade override classes */

.hiive-welcome .fr-announcementTitle::before {
  position: absolute;
  display: inline-block;
  height: 32px;
  width: 32px;
  content: url(/hiive-icon-only.svg);
  top: 0;
}

.fr-modalContainer a,
.fr-cornerModalContainer a {
  color: var(--chakra-colors-teal-900);
}

.fr-modalContainer a:hover,
.fr-cornerModalContainer a:hover {
  text-decoration: underline;
}

.fr-cornerModalContainer {
  right: var(--chakra-space-12);
  bottom: var(--chakra-space-12);
}

@media screen and (max-width: 30em) {
  .fr-cornerModalContainer {
    left: 50%;
    transform: translate(-50%, -15%);
    height: fit-content;
  }
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 12px;
}

::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: var(--chakra-colors-grey-200);
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

/* FrontChat styles */
#front-chat-iframe {
  overscroll-behavior: none;
}

.listing-details-md {
  white-space: pre-wrap;
}

.textArea {
  border-radius: 6px;
  padding: 8px 16px;
  white-space: pre-line;
  background-color: white;
  color: #323232;
  width: 100%;
  line-height: 22px;
  border-color: #818181;
  border-width: 1px;
  resize: none;
  appearance: none;
  box-sizing: border-box;
  outline: none;
}

.textArea::placeholder {
  color: #575757;
}
