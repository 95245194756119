.input {
  height: 100% !important;
  width: 100% !important;
  border-top-right-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-color: var(--chakra-colors-h-mid-grey) !important;
  background: white !important;
  color: var(--chakra-colors-h-dark-grey) !important;
}

.input:hover {
  border-color: var(--chakra-colors-gray-300);
}

.input:focus {
  box-shadow: var(--chakra-shadows-focus) !important;
  border-color: var(--chakra-colors-grey-600) !important;
}

.inputButton {
  background: white !important;
  border-radius: 6px 0 0 6px !important;
  border-color: var(--chakra-colors-h-mid-grey) !important;
}

.inputButton:hover {
  background: white !important;
  border-color: var(--chakra-colors-gray-300);
  border-radius: 6px 0 0 6px !important;
}

.container {
  height: 40px !important;
  background: white !important;
  border-color: var(--chakra-colors-h-mid-grey) !important;
  border-radius: 6px !important;
}

.container:hover {
  border-color: var(--chakra-colors-gray-300);
}

.dropdown {
  border-radius: 6px !important;
  border: 1px solid var(--chakra-colors-h-mid-grey) !important;
}

.error {
  border-color: var(--chakra-colors-red-500) !important;
  box-shadow: 0 0 0 1px var(--chakra-colors-red-500) !important;
}
